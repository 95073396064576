import React from 'react';

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <small>&copy; 2008 - {currentYear} : J/Victoria College, Chulipuram, Sri Lanka<br /><br /></small>
      <hr />
      <small><em>
      <a href="https://125years.jvictoriacollege.net">This website</a> is developed in May, 2022 and being maintained by <a href="https://beta.jvictoriacollege.ca/pages/executive-committee-current" target="_blank">JVC OSA, Canada</a> to archive J/Victoria College's historical publications on the Internet for future references.
      </em></small>
      <br /><br />
    </>
  );
};
