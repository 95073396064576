const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://125years.jvictoriacollege.net',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    logo: '',
    logoLink: 'https://125years.jvictoriacollege.net',
    title: 'Online Version of 125 Years Anniversary Book (1876 - 2001)',
    githubUrl: '',
    helpUrl: '',
    tweetText: '',
    social: '',
    links: [{ text: '', link: '' }],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/பொருளடக்கம்',
      '/கல்லூரிக்-கீதம்', // add trailing slash if enabled above
      '/விழாக்குழு',
      '/மலர்க்குழு',
      '/தேவாரம்',
      '/பண்பு-நிறை-கலைக்கூடம்',
      '/அதிபரின்-ஆசி',
      '/மலர்-ஆசிரியர்-பேனாவிலிருந்து',
      '/ஆசிச்-செய்தி',
      '/வாழ்த்துரை-1',
      '/பிரார்த்தனை-உரை',
      '/வாழ்த்துரை-2',
      '/விநாயகன்-அருள்வான்',
      '/ஆசியுரை',
      '/கல்லூரி-வரலாறும்-வாழ்வும்',
      '/அழியாச்-செல்வம்-கல்விச்-செல்வம்',
      '/நிறுவுநர்-நோக்கம்-ஓர்-ஆய்வு',
      '/அமரர்-திருமதி-அன்னப்பிள்ளை-செல்லப்பா',
      '/சூழல்-சிறக்க-சேவை-மலரட்டும்',
    ],
    collapsedNav: [
      '/codeblock', // add trailing slash if enabled above
    ],
    links: [
      { text: 'School Website', link: 'http://web.archive.org/web/20211229011223/http://www.victoriacollege.lk/' },
      { text: 'JVC OSA, Chulipuram, Sri Lanka', link: '' },
      { text: 'JVC OSA, Australis', link: '' },
      { text: 'JVC OSA, Canada', link: 'https://www.jvictoriacollege.ca' },
      { text: 'JVC OSA, UK', link: '' },
    ],
    frontline: false,
    ignoreIndex: true,
    title: '',
  },
  siteMetadata: {
    title: 'J/Victoria College, Chulipuram\'s 125th Year Anniversary Book',
    description: 'Online version of the 125th year anniversary book of J/Victoria College, Chulipuram',
    ogImage: 'https://125years.jvictoriacollege.net/static/logo-a1c783482d1dc3e8294b5354de25dc70.png',
    docsLocation: 'https://github.com/hasura/gatsby-gitbook-boilerplate/tree/master/content',
    favicon: 'https://graphql-engine-cdn.hasura.io/img/hasura_icon_black.svg',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'J/Victoria College, Chulipuram\'s 125th Year Anniversary Book',
      short_name: 'JVC_125_Years',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
